import * as React from 'react'
import { Link } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Card } from 'react-bootstrap'
import styled from '@emotion/styled'
import Typist from 'react-typist'
import { Helmet } from 'react-helmet'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import ParticleComponent from '../components/particleComponent'
import LargeK from '../assets/KematixDigitalLogoLargeLetter.svg'
import AIImage from '../assets/ai.png'
import Ipad from '../assets/KematixLogoipad.svg'
import Laptop from '../assets/KematixLogolaptop.png'
import LaptopMobile from '../assets/KematixLogolaptopmobile.png'
import Speech from '../assets/KematixLogosppech.png'
import TimelineComponent from '../components/timelineComponent'
import FooterComponent from '../components/footerComponent'
import bingo1 from '../assets/apple-touch-icon.png'
import bingo2 from '../assets/favicon-32x32.png'
import bingo3 from '../assets/favicon-16x16.png'
import bingo5 from '../assets/safari-pinned-tab.svg'
import TsImage from '../assets/KematixLogots.svg'
import ReactImage from '../assets/KematixLogoreact.png'
import AwsImage from '../assets/KematixLogoaws.png'
import DdbImage from '../assets/KematixLogoddb.png'
import GraphqlImage from '../assets/KematixLogographql.png'
import NodeImage from '../assets/KematixLogonode.png'
import PSQLImage from '../assets/KematixLogopsql.png'
import PWAImage from '../assets/KematixLogopwa.png'
import ServerlessImage from '../assets/KematixLogoserverless.png'
import Gatsby from '../assets/KematixLogogatsby.png'
import iOSImage from '../assets/appleIcon.svg'
import androidImage from '../assets/androidLogo.svg'

const TitleWords = styled.h1`
  font-style: normal;
  font-weight: 300;
  font-size: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #ffffff;
  justify-content: center;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  @media only screen and (max-width: 852px) {
    margin: auto;
    flex-direction: column;
    align-items: center;
  }
`

const H2Container = styled.h2`
  font-style: normal;
  font-weight: 400;
  color: #1b1d2a;

  font-size: 32px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  @media only screen and (max-width: 536px) {
    font-size: 21px;
  }
`

const StyledButton = styled(Link)`
  width: 22em;
  height: 4em;
  background: #f1b631;
  border: 0.5px solid #f1b631;
  box-sizing: border-box;
  border-radius: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
  text-decoration: none;
  margin-top: 25px;
  @media only screen and (max-width: 536px) {
    width: 12em;
    height: 3em;
  }
  :hover {
    text-decoration: none;
  }
  margin: 16px;
`
const HeroComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const SevenStepComponent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #252839;
`

const HeroInfo = styled.div`
  position: absolute;
`
const AboutUsSection = styled.div`
  h4 {
    font-style: normal;
    font-weight: 300;
    font-size: 25px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #252839;
  }
  padding-top: 83px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1866px) {
    flex-direction: column;
    padding-top: 0px;
  }
  @media only screen and (max-width: 878px) {
    flex-direction: column;
    padding-top: 0px;
  }
`

const LargeKImage = styled.img`
  border-right: 2px solid #9e9e9e4d;
  margin-right: 102px;
  padding-right: 129px;
  padding-bottom: 25px;
  height: 308px;
  padding-top: 25px;
  width: 395px;
  @media only screen and (max-width: 878px) {
    border-bottom: 2px solid #9e9e9e4d;
    border-right: none;
    padding-bottom: 25px;
    padding-right: 0px;
    height: 308px;
    margin: auto;
  }
  @media only screen and (max-width: 1866px) {
    border-right: 0px solid #9e9e9e4d;
    padding-bottom: 25px;
    height: 308px;
    padding-top: 5px;
    width: 395px;
    margin: auto;
    margin-top: 167px;
    padding-right: 0px;
  }
`
const TitleSection = styled.div`
  text-align: center;
`

const Title = styled.h1`
  :before {
    content: '';
    border-bottom: 3px solid #f1b631;
    position: absolute;
    width: 21%;
    left: 40.5%;
    bottom: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
  }
  text-align: center;
  padding-bottom: 10px;
  padding-top: 57px;
  display: inline-block;
  position: relative;
`

const PlanTitle = styled.h1`
  :before {
    content: '';
    border-bottom: 3px solid #f1b631;
    position: absolute;
    width: 21%;
    left: 40.5%;
    bottom: 0;
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
  }
  text-align: center;
  padding-top: 57px;
  padding-bottom: 10px;
  color: white;
  display: inline-block;
  position: relative;
`

const ServiceCardsSection = styled.div`
  display: flex;
  justify-content: inherit;
  @media only screen and (max-width: 878px) {
    min-height: 64%;
    display: flex;
    flex-flow: row wrap;
    justify-content: inherit;
    margin-bottom: 300px;
  }
  @media only screen and (min-width: 1865px) {
    min-height: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: inherit;
  }
  @media only screen and (min-width: 500px) {
    min-height: 49%;
    display: flex;
    flex-flow: row wrap;
    justify-content: inherit;
  }
  margin-bottom: 50px;
`

const Cards = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 15vw;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin: 10px;
  background: #ffffff;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  min-height: 465px;
  width: 350px;
  @media only screen and (max-width: 878px) {
    height: 100px;
    min-height: 530px;
  }
`

const ServiceImages = styled.img`
  width: 7em;
  margin: auto;
  padding-top: 22px;
`
const StyledServiceButton = styled(Link)`
  height: 3em;
  background: #f1b631;
  border: 0.5px solid #f1b631;
  box-sizing: border-box;
  border-radius: 52px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 15px;
  position: absolute;
  bottom: 0;
  width: 226px;
  position: initial;
  margin-bottom: 20px;
  :hover {
    text-decoration: none;
  }
`
const ServiceH2 = styled.h2`
  font-style: normal;
  font-weight: 200;
  font-size: 25px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #1b1d2a;
`
const ServiceH3 = styled.h3`
  text-align: center;
  font-weight: bold;
`
const StyledTypist = styled(Typist)`
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  font-size: 2.4vmax;
  font-size: 4vmin;
`

const TechGroup = styled.div`
  display: flex;
  margin-right: 30px;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;
  justify-content: center;
  align-items: center;
`
const TechImage = styled.img`
  margin-right: 5px;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  // mix-blend-mode: luminosity;
  max-height: 40px;
`
const TechName = styled.h1`
  font-family: Raleway;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  color: #888888;
  margin-bottom: 0;
`

const descriptions = ['Software Experts You Can Count On']

const IndexPage = () => {
  return (
    <IndexLayout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kematix Digital | Software Experts</title>

        <link rel="apple-touch-icon" sizes="180x180" href={bingo1} />
        <link rel="icon" type="image/png" sizes="32x32" href={bingo2} />
        <link rel="icon" type="image/png" sizes="16x16" href={bingo3} />
        <link rel="mask-icon" href={bingo5} color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="canonical" href="https://kematixdigital.com/" />
      </Helmet>
      <HeroComponent>
        <HeroInfo>
          <TitleWords>
            <StyledTypist>
              {descriptions.map((description, index) => {
                return (
                  <div key={index}>
                    <span>{description}</span>
                    {index < descriptions.length - 1 ? <Typist.Backspace ms={10000} delay={2000} count={description.length} /> : <></>}
                  </div>
                )
              })}
            </StyledTypist>
          </TitleWords>
          <ButtonContainer>
            <StyledButton to="/#kematix-services">
              <H2Container>Services</H2Container>
            </StyledButton>
            <StyledButton to="/contact-us">
              <H2Container>Contact Us</H2Container>
            </StyledButton>
          </ButtonContainer>
        </HeroInfo>

        <ParticleComponent />
      </HeroComponent>
      <Container>
        <AboutUsSection>
          <LargeKImage src={LargeK} alt="Large Kematix Navbar Logo" width="300" />
          <h4>
            We help startups and enterprises create cutting-edge, innovative software products that optimize efficiency and foster growth.
            We're your team of software experts.
            <br />
            <br />
            We work with you through every step of the creation process to ensure that the end product delivers the best solution for your
            needs.
          </h4>
        </AboutUsSection>
        <br id="kematix-services" />
        <TitleSection>
          <Title>Services</Title>
        </TitleSection>
        <ServiceCardsSection>
          <Cards>
            <ServiceImages style={{ paddingBottom: '5px' }} alt="Kematix Digital Icon of AI Development Icon" src={AIImage} />
            <ServiceH3>AI Development</ServiceH3>{' '}
            <Card.Body
              style={{ padding: '1rem', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignItems: 'center' }}
            >
              <Card.Text style={{ paddingBottom: '50px', color: 'black', textAlign: 'center' }}>
                We create AI solutions that can level up your business. From bespoke virtual assistants to powerful chatbots to efficient
                document analysis, our team of experts can craft cutting-edge AI solutions that deliver scalable, secure, and measurable
                results.
              </Card.Text>
            </Card.Body>
          </Cards>
          <Cards>
            <ServiceImages alt="Kematix Digital Icon of Mobile App Development Icon" src={Ipad} />
            <ServiceH3>Mobile App Development</ServiceH3>{' '}
            <Card.Body
              style={{ padding: '1rem', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignItems: 'center' }}
            >
              <Card.Text style={{ paddingBottom: '50px', color: 'black', textAlign: 'center' }}>
                We create mobile apps that look, feel and respond in the way you need them to. We’ll work with you closely to gather your
                requirements and tailor our approach to develop innovative mobile app solutions fit for your business.
              </Card.Text>
            </Card.Body>
          </Cards>
          <Cards>
            <ServiceImages alt="Kematix Digital Icon of a Laptoo with a Gear on the top right hand corner" src={LaptopMobile} />
            <ServiceH3>Custom Web App / Progressive Web App Development</ServiceH3>{' '}
            <Card.Body
              style={{ padding: '1rem', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignItems: 'center' }}
            >
              <Card.Text style={{ paddingBottom: '50px', color: 'black', textAlign: 'center' }}>
                We use the latest technologies and programming approaches to build your feature-rich custom web app or progressive web app.
                Always up to the latest best practices, our custom web apps and progressive web apps run fast, remain secure, and provide an
                intuitive and easy to use experience for any user.
              </Card.Text>
            </Card.Body>
          </Cards>
          <Cards>
            <ServiceImages alt="Kematix Digital Icon of Enterprise Software / API Development" src={Laptop} />
            <ServiceH3>Enterprise Software / API Development</ServiceH3>{' '}
            <Card.Body
              style={{ padding: '1rem', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignItems: 'center' }}
            >
              <Card.Text style={{ paddingBottom: '50px', color: 'black', textAlign: 'center' }}>
                We create custom enterprise software for companies that need a solution to optimize repetitive workflow, need to modernize
                legacy software, or want to improve productivity.{' '}
              </Card.Text>
            </Card.Body>
          </Cards>
          <Cards>
            <ServiceImages alt="Kematix Digital Icon of Speech Bubble Icon" src={Speech} />
            <ServiceH3>Consulting</ServiceH3>{' '}
            <Card.Body
              style={{ padding: '1rem', display: 'flex', justifyContent: 'space-evenly', flexDirection: 'column', alignItems: 'center' }}
            >
              <Card.Text style={{ paddingBottom: '50px', color: 'black', textAlign: 'center' }}>
                Our software consultants can help you build a scalable software strategy that fits your business ecosystem and aligns with
                your company’s values and budgets.{' '}
              </Card.Text>
            </Card.Body>
          </Cards>
        </ServiceCardsSection>
      </Container>
      <SevenStepComponent>
        <TitleSection>
          <PlanTitle>Our 6 Step Plan</PlanTitle>
        </TitleSection>
        <TimelineComponent />
      </SevenStepComponent>

      <Container>
        <TitleSection>
          <Title>Our Tech</Title>
        </TitleSection>{' '}
        <ServiceCardsSection>
          <TechGroup>
            <TechImage draggable="false" alt="TypeScript Icon Logo" src={TsImage} />
            <TechName>TypeScript</TechName>
          </TechGroup>
          <TechGroup>
            <TechImage draggable="false" alt="React Icon Logo" src={ReactImage} />
            <TechName>React</TechName>
          </TechGroup>
          <TechGroup>
            <TechImage draggable="false" alt="React Native Icon Logo" src={ReactImage} />
            <TechName>React Native</TechName>
          </TechGroup>
          <TechGroup>
            <TechImage draggable="false" alt="iOS Icon Logo" src={iOSImage} />
            <TechName>iOS</TechName>
          </TechGroup>
          <TechGroup>
            <TechImage draggable="false" alt="Android Icon Logo" src={androidImage} />
            <TechName>Android</TechName>
          </TechGroup>
          <TechGroup>
            <TechImage style={{ maxHeight: '25px' }} draggable="false" alt="Progressive Web App Icon Logo" src={PWAImage} />
            <TechName>Progressive Web App</TechName>
          </TechGroup>{' '}
          <TechGroup>
            <TechImage draggable="false" alt="GatsbyJS Icon Logo" src={Gatsby} width="35px" />
            <TechName>Gatsby</TechName>
          </TechGroup>
          <TechGroup>
            <TechImage draggable="false" alt="NodeJS Icon Logo" src={NodeImage} />
            <TechName>NodeJS</TechName>
          </TechGroup>{' '}
          <TechGroup>
            <TechImage draggable="false" alt="Amazon Web Service Icon Logo" src={AwsImage} />
            <TechName>AWS</TechName>
          </TechGroup>{' '}
          <TechGroup>
            <TechImage draggable="false" alt="Serverless Icon Logo" src={ServerlessImage} />
            <TechName>Serverless</TechName>
          </TechGroup>
          <TechGroup>
            <TechImage draggable="false" alt="GraphQL Icon Logo" src={GraphqlImage} />
            <TechName>GraphQL</TechName>
          </TechGroup>
          <TechGroup>
            <TechImage draggable="false" alt="DynamoDB Icon Logo" src={DdbImage} />
            <TechName>DynamoDB</TechName>
          </TechGroup>
          <TechGroup>
            <TechImage draggable="false" alt="PostgreSQL Icon Logo" src={PSQLImage} />
            <TechName>PostgreSQL</TechName>
          </TechGroup>{' '}
        </ServiceCardsSection>
      </Container>

      <FooterComponent />
    </IndexLayout>
  )
}

export default IndexPage
